.nav_container {

}

.navi_site_name {
    padding: 0px 10px;
    color: black;   
}

.navi_site_name:hover{
    cursor: pointer
}

.navi_icon {
    font-size: 30pt !important;
    margin: 2px 10px;
    text-align: right;
}

.profile_icon {
    font-size: 30pt !important;
    color: black;
    margin: 2px -15% 2px 10px;
    text-align: right;
}

.nav_container {
    width: 95%;
    display: flex;
    justify-content: space-between;
}

.nav_options {
    display: flex;
    align-items: center;
}

.nav_item {
    text-transform: none;
    color: black;
    font-weight: bold!important;
}

.down_arrow {
    color: black;
}

.mobile_button {
    display:flex;
    flex-direction: column;
    text-transform: none;
    color: black!important;
}

@media (max-width: 600px){
    .nav_container{
        background-color: white;
        position: fixed!important;
        top: auto!important;
        bottom:0!important;
        z-index: 10;
    }
}
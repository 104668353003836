@import url('https://fonts.googleapis.com/css2?family=Silkscreen&family=Source+Sans+Pro&display=swap');
body {
    background-color: #7bafd4;
}

.site_name {
    font-family: 'Silkscreen', cursive;
    margin: 0px 0px 5px 0px;
}

.robie_pic {
    height: 20%;
    width: auto;
    margin-top: 30px;
}

.login_container{
    justify-content: center;
    align-items: center;
    background: #ffffff;
    box-shadow: 0px 0px 5px 2px #888888;
    border-radius: 5pt;
    overflow: hidden;
}
.login_textBox {
    margin: 5px; 
    width: 80%;
}

.submit_button {
    padding: 10px 20%;
    font-size: 16px;
    margin: 5px;
    color: #ffffff;
    background-color: #c8102e !important;
    border: none;
    border-radius: 3pt;
}

.login_image {
    height: 100%; 
    object-fit: cover;
    width: 100%;
}

.forgot_psswd {
    font-size: 10pt;
    padding-left: 10%;
    float: left;
    color: #037CFF;
}

.create_account {
    font-size: 10pt;
    padding-right: 10%;
    float: right;
    color: #037CFF;
}

.gooLoginButton{
    display: flex;
    justify-content: center;
    align-items: center;
}

.contact_info_container{
    justify-content: center;
    align-items: left;
    /* background: #e4e9f6; */
    overflow: hidden;
}

.contact_info{
    display: flex;
    justify-content: left;
    align-items: left;
    /* background: #7bafff; */
    margin-left: 40px;
    margin-top: 20px;
}

.contact_info_email{
    display: flex;
    justify-content: left;
}
.page{
    display: flex;
    flex-direction: column;
    padding-left: 10px;
    padding-right: 10px;
    background-color: black;
}

.user_profile{
    display: flex;
    flex-direction: column;
    padding-left: 10px;
    padding-right: 10px;
    background-color:aqua;
}

.bio{
    display: flex;
    flex-direction: column;
    align-items: center;
    /* text-align: left; */
    background-color: gray;
}

.profile_image{
    background-color:black;
    border-radius: 50%;
    margin: 10px;
    height: 100px;
    width: 100px;
    z-index: +1;
}

.posts{
    display: flex;
    flex-direction: column;
    text-align: left;
    background-color:white;
}

/* -------------- */

body{
    margin:0;
    padding:0;
    font-family: 'Source Sans Pro', sans-serif;
    background:#e3e9f7;
}

.section{
    display:grid;
    place-items:center;
    min-height:100vh;
    text-align:center;
}

.box-area h2{
    text-transform:uppercase;
    font-size:30px;
}

@media (max-width: 600px){
    .wrapper{
        margin-top: 35px;
    }
}
.comment {
    display: flex;
    justify-content: left;
}

.avatar {
    width: 50px;
    height: 50px;
}

.text_container {
    margin: 5px;
}

.header {
    display: flex;
}

.username {
    color: #7bafd4;
    font-weight: bold!important;
}

.date {
    margin-left: 10px;
    padding-left: 5px;
}
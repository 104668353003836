.container {
    min-width: 120px;
    margin: 5px
}

.grid {
    margin: 0 auto;
}

.request-edit{
    flex: 0 1 auto;
    margin-left: auto;
}

.request-list {
    display:flex;
    align-items: center;
    overflow: hidden;
}

.request-options{
    display: flex;
}

.friends-header {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    position: relative;
    margin-top: 5px;
}

.friends-title{
    flex: 0 1 auto;
    position: absolute;
    left: 50%;
    transform: translate(-50%)
}

.friends-edit {
    flex: 0 1 auto;
    margin-left: auto;
}

.friend-name {
    display: flex;
    align-items: center;
    overflow: hidden;
}

.user-card {
    display: flex;
    justify-content: space-between;
}

.user-header {
    display: flex;
    align-items: center;
    width: 90%;
    overflow: hidden;
}

@media (max-width: 900px){
    .friends-header {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        position: relative;
        margin-top: 0;
    }
}
.feedback_container {
    display: flex;
    justify-content: space-between;
    margin: 0 5px;
    height: 40px;
}

.comment_total {
    display: flex;
    align-items: center;
}

.comment_total:hover {
    cursor: pointer;
}

.comment_number {
    margin-right: 3px;
}

.like_total {
    display: flex;
    align-items: center;
}

.interact_container {
    display: flex;
    justify-content: space-between;
    padding: 10px;
}

.interact {
    display: flex;
    transition: all .3s;
    border-radius: 25px;
    padding: 5px;
}

.interact:hover {
    cursor: pointer;
    box-shadow: 0 0 11px rgba(33,33,33,.2);
    background: rgba(33,33,33,.2);
}
/*Begin unknown component styling*/
.project_container{
    width: 90%;
    max-width: 1000px;
    margin: auto;
}

.comments{
    display: flex;
    flex-direction: column;
    background-color: cadetblue;
    border-radius: 10px;
    margin-bottom: 10px;

}

.images_component{
    flex: 1;
    background-color:cornflowerblue;
    margin-bottom: 10px;
    object-fit: scale-down;
    border: 2px solid rgb(0, 0, 9);
}

.images{
    height: 600px;
    width: auto;
}

/* .post_img{
    cursor: pointer;
    border: 1px solid #555;
}

.post_img:hover{
    opacity: .5;
} */

/*End unknown component styling*/

/*Begin styling for project cards (in project.js)*/
.project {
    margin-top: 10px;
}

.project_header{
    display: flex;
    justify-content: space-between;
}

.project_info{
    display: flex;
    align-items: center;
    width: 40%;
}

.avatar {
    margin:10px;
}

.project_details_home{
    width: 100%;
}

.project_details_profile{
    padding: 5px 25px;
}

.user_name{
    display: flex;
}

.friend_button {
    margin-left: 5px;
}

.project_date {
    display: flex;
    margin: 5px;
}

.project_visibility {
    display: flex;
    align-items: normal;
    cursor: pointer;
    height: 10px;
}

.visibility_icon {
    margin: 1px 2px 0 2px;
}

.post_container {
    display: flex;
    margin: 5px 5px 0 10px;
}

.post_img{
    width: 300px;
    object-fit: cover;
    margin:  5px 10px;
}

.post_description {
    text-align: left;
    padding: 5px;
}

/*End Styling for project cards*/

/*Project cards mobile styles*/

@media (max-width: 600px){
    .project_container{
        margin-top: 30px;
    }

    .post_container{
        display: block;
        margin: 5px;
    }
    .post_description {
        width: 75%;
        font-size: x-small!important;
        text-align: center;
        margin: auto!important;
    }
    .post_img {
        height: 200px;
    }
}
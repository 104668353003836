.menu{  
    display: flex;
    flex-direction: column;
    height: 30px;
    background-color: #dddddd;
    justify-content: center;
}

.titles{
    display: block;
    margin-block-start: 0.5em;
    margin-block-end: 0.5em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    font-weight: bold;
}

.dates{
    color:darkgray;
    font-size:medium;
    font-weight: bold;
}


.default_container{
    display: flex;
    flex-direction: column;
    align-items: left;
    border-radius: 10px;
    background-color:white;
    box-shadow: 0px 0px 5px 1px lightgrey;
    margin: 10px;
    padding: 20px;
}

.response{
    display: flex;
    flex-direction: column;
    align-items: left;
    border-radius: 10px;
    /* border: 2px solid rgb(0,0,255); */
    background-color:lightblue;
    box-shadow: 0px 0px 5px 1px lightgrey;
    margin: 10px;
    padding: 5px;
    /* width: 400px */
    /* width: 90vw; */

}


.defaultBox{
    font-size: 16px;
    text-align: left;
    background-color:white;
    margin: 10px;
    padding: 5px;
    /* width: 70vw; */
    /* height: 40vh; */
}
.resp_btn {
    padding: 10px 30px;
    font-size: 16px;
    margin: 5px;
    color: #ffffff;
    background-color: #c8102e !important;
    border: none;
    border-radius: 3pt;
}



.date{
    text-align: right;
    padding-bottom: 10px;
}

.essay{
    text-align: left;
    /* padding-bottom: 10px; */
    background-color: white;
    padding: 10px;
    border-color: lightblue;
    border-style: solid;
}

.box1{
    display: flex;
    flex-direction: row;
    height: auto;
    width: auto;
    padding: 10px;
}

.descriptions{
    width: 50vw;
    height: auto;
    padding: 5px;
}

.all_images{
    background-color: lightgray;
    width: 35vw;
    height: auto;
    padding: 5px;
}

.image_box{
    height: auto;
    width: 100px;
}

.figures_label{
    text-align: left;
    padding-bottom: 10px;
}

.all_figures{
    display: flex;
    flex-direction: row;
    height: auto;
    width: auto;
    padding: 10px;
}

.figures{
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    border-color: lightblue;
    border-style: solid;
}

.figure_images{
    max-width: 280px;
    max-height: 200px;
    height: auto;
}

.figure_captions{
    text-align: left;
    justify-content: center;
    max-width: 280px;
    max-height: 200px;
    background-color: #ffffff;
    padding: 10px;
    min-height: 40px;
}


.set_presentation{
    display: flex;
    flex-direction: row;
}

.selectMenu{
    border-radius: 10px;
    background-color:lightblue;
    box-shadow: 0px 0px 5px 1px lightgrey;
    width: 200px;
    margin: 5px;
    padding-top: 5px;
    padding-left: 5px;
}


.responseTemp{
    display: flex;
    flex-direction: column;
    align-items: left;
    border-radius: 10px;
    background-color:lightblue;
    box-shadow: 0px 0px 5px 1px lightgrey;
    margin: 5px;
    padding: 10px;
    width: 250px;
}

.dropDown{
    background-color: #ffffff;
}

.divOR{
    /* background-color: red; */
    text-align: center;
    padding-top: 50px;
    font-weight: bold;
    font-size: 25px;
    padding-left: 10px;
    padding-right: 10px;
}

.alignOptions{
    display: flex;
    flex-direction: column;
    align-items: left;
}

.googleDisplay{
    display: flex;
    flex-direction: row;
}

.googleUser{
    display: flex;
    flex-direction: row;
    height: 50px;
    background-color: #4285F4 ;
    width: fit-content;
    border-radius: 25px;
}

.googleUserInfo{
    display: flex;
    flex-direction: column;
    justify-content: left;
}

.googleUserPicture{
    border-radius: 50%;
}